import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import GetInvolved from '../components/Page/GetInvolved'
import BannerImage from '../components/Page/BannerImage'
import PaypalDonateFormCard from '../components/Form/PaypalDonateFormCard'

const getInvolvedPage = ({data}) => {
	return (
        <Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className="md:w-1/2">
					<GetInvolved />
				</div>
				<div className="md:w-1/2 p-4 md:p-12">
					<PaypalDonateFormCard
						title={`Support the Festival`}
						initialValue={25}
						description={(
							<>
								<p className={`mb-2`}>We are indebted to all who have supported the Boswell Book Festival over the past ten years in so many different ways. It is only with the help of so many loyal supporters that the Festival has achieved recognition as a cultural event of national importance.</p>
							</>
						)}
						headerClassName={`bg-bbf-yellow`}
					/>
				</div>
			</section>
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "general/Bozzy-16-9-006.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default getInvolvedPage
